import { staticClient } from 'clients/static';

export type ContentCachePayload = {
  contentfulPages: string[];
};

export async function queryContentfulPages(): Promise<string[]> {
  const response = await staticClient.get<ContentCachePayload>('/api/contentful/pages');

  return response.data.contentfulPages;
}
