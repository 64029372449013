import { queryContentfulPages } from 'clients/contentful/contentfulPages';
import { loadContentfulGenericData } from 'lib/contentful/loadContentfulData';
import { arrayToRelativePath, relativePathToArray } from 'lib/relativePaths';
import { notFound, redirect } from 'next/navigation';
import { truckMapConfig } from 'truckMapConfig';

const localesFallback = [truckMapConfig.i18n.defaultLocale];
const fastDevBuild = truckMapConfig.env === 'development';

export async function generateStaticParams() {
  if (fastDevBuild) return [];

  const pageEntries = await queryContentfulPages();

  return pageEntries.flatMap((relativePath) => {
    const normalizedPath = relativePathToArray(relativePath);
    return (truckMapConfig.i18n.locales ?? localesFallback).map((locale) => ({
      relativePath: normalizedPath,
      locale
    }));
  });
}

export async function getStaticPageContent({ relativePath = [] }: { relativePath?: string[] }) {
  try {
    const legacyRelativePath = arrayToRelativePath(relativePath);
    console.log('>>>> Fetching contentful layout', legacyRelativePath);

    const {
      contentfulQuery,
      legacyLayoutResponse: [page]
    } = await loadContentfulGenericData({
      legacyRelativePath
    });

    if (!page) {
      notFound();
    }

    return { page, contentfulQuery };
  } catch (error) {
    console.error('Static page error:', { error, relativePath });
    throw error;
  }
}

export async function getErrorPageContent() {
  try {
    return await getStaticPageContent({
      relativePath: ['_500']
    });
  } catch (error) {
    console.error('Error page failed to load, redirecting to homepage:', error);
    redirect('/');
  }
}
