'use client';
import { Page } from 'components/Page';
import { LoadingScreen } from 'components/common/LoadingSpinner/LoadingScreen';
import { getErrorPageContent } from 'lib/contentful/getStaticPageContent';
import { useEffect, useState } from 'react';
import { ErrorCodes, StaticPageProps } from 'types/common';

export default function Error() {
  const [pageData, setPageData] = useState<StaticPageProps>(null);

  useEffect(() => {
    getErrorPageContent().then(setPageData);
  }, []);

  if (!pageData) {
    return <LoadingScreen />;
  }

  return <Page {...pageData} statusCode={ErrorCodes.ERROR} />;
}
